<template>
  <div>在线签到</div>
</template>

<script>
export default {
  name: "sign-in"
}
</script>

<style scoped>

</style>